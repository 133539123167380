import DOMPurify from 'dompurify';

angular.module('LeasePilot').directive('newDocumentModal', [
  '$window',
  'DocumentService',
  '$rootScope',
  '$mdDialog',
  '$http',
  '$compile',
  function($window, DocumentService, $rootScope, $mdDialog, $http, $compile) {
    return {
      restrict: 'E',
      templateUrl: 'new_document_modal.html',
      link: function(scope, element, attrs) {
        scope.document = {
          tenantInfo: {},
          type: 'Lease',
        };

        scope.creating = false;
        scope.errors = [];
        scope.dealType = 'retail';

        // VTS
        scope.vtsDealID = '';
        scope.importTaskID = '';
        scope.isVTSDealSelected = false;
        scope.vtsDealTitle = '';
        scope.isVtsEnabled =
          $rootScope.user &&
          $rootScope.user.company.companyProfile.integrationsConfig &&
          $rootScope.user.company.companyProfile.integrationsConfig.vts &&
          $rootScope.user.company.companyProfile.integrationsConfig.vts
            .enabled === true;
        scope.vtsErrors = [];

        // Salesforce
        scope.isSalesforceOpportunitySelected = false;
        scope.salesforceOpportunityId = '';
        scope.isSalesforceEnabled =
          $rootScope.user &&
          $rootScope.user.company.companyProfile.integrationsConfig &&
          $rootScope.user.company.companyProfile.integrationsConfig
            .salesforce &&
          $rootScope.user.company.companyProfile.integrationsConfig.salesforce
            .enabled === true;
        // TODO: implement
        scope.salesforceDocumentType =
          scope.isSalesforceEnabled === true
            ? $rootScope.user.company.companyProfile.salesforceDocumentType
            : 'Lease';

        scope.tenantFocus = false;
        scope.companyId = attrs.companyId || undefined;
        scope.copyDealTerms = false;
        scope.showCopyDealTerms = false;
        
        if (window.lease)  {
          scope.copyDealTerms = true;
          scope.showCopyDealTerms = true;
          scope.copyDealTermsFrom = `${$rootScope.title.tenant} - ${$rootScope.title.building} (Version ${$rootScope.title.version.major}.${$rootScope.title.version.minor})`;
        }

        if (
          $rootScope.user &&
          $rootScope.user.company.companyProfile &&
          $rootScope.user.company.companyProfile.hasConformedDeals
        ) {
          DocumentService.query(
            { conformedDeals: true },
            { type: 'lease' },
          ).then(
            function(conformedDeals) {
              scope.tenants = {};
              
              conformedDeals.forEach((deal, index) => {
                let tenantName = "";
  
                if (deal.form.customKeyFields) {
                  tenantName = deal.customFields.fileName;
                } else {
                  if (deal.form.dealType === 'office') {
                    tenantName = deal.tenantInfo.name;
                  } else if (deal.form.dealType === 'retail') {
                    tenantName = deal.tenantInfo.tradeName;
                  } else if (deal.form.dealType === 'industrial') {
                    tenantName = deal.tenantInfo.name;
                  }
                }
  
                if (!scope.tenants[tenantName]) {
                  scope.tenants[tenantName] = {
                    name: tenantName,
                    conformedDeals: {},
                  };
                }

                scope.tenants[tenantName].conformedDeals[deal.form.displayName] = deal;
              });
            },
            function(err) {
              console.log(err);
            },
          );
        }

        scope.findConformedDeal = function() {
          let fileName;

          if (scope.document?.form?.customKeyFields) {
            const lease = scope.document;
            
            let customKeyFields = scope.document.form.customKeyFields;
              
            if (typeof customKeyFields === "string") {
              customKeyFields = JSON.parse(customKeyFields);
            }
            
            customKeyFields = customKeyFields.filter(field => field.type !== "building");
            
            fileName = _.get(lease, customKeyFields[0].ref.replace("lease.", ""));

            for (let index = 1; index < customKeyFields.length; index++) {
              const key = customKeyFields[index].ref.replace("lease.", "");
              fileName += ` - ${_.get(lease, key)}`;
            }
          } else {
            fileName = scope.searchTenantText;
          }
          
          if (scope.tenants) {
            const item = scope.tenants[fileName];
  
            if (item) {
              const result = item.conformedDeals[scope.flavor];
              scope.selectedConformedDeal = result;
              return result;
            }
  
            return;
          }
        };

        scope.createCustomKeyFields = function() {
          const container = document.querySelector('.custom-key-fields');
          container.innerHTML = '';

          let customKeyFields = scope.document.form.customKeyFields;

          if (typeof customKeyFields === "string") {
            customKeyFields = JSON.parse(customKeyFields);
          }

          for (let index = 0; index < customKeyFields?.length; index++) {
            if (customKeyFields[index].type === "text") {
              const ref = customKeyFields[index].ref.replace("lease", "document");
              const label = customKeyFields[index].label;
              const key = _.camelCase(label);
              const element = $(`
                <md-input-container>
                  <div class="row vertically-align-content">
                    <div class="col m4">${label}</div>
                    <div class="col m8">
                      <input aria-label="${label}" autocomplete="${ref}" id="${ref}" ng-blur="validate()" ng-keyup="findConformedDeal(${ref})" ng-model="${ref}" type="text"><div class="md-errors-spacer"></div>
                    </div>
                  </div>
                </md-input-container>
              `)[0];

              container.appendChild(element);
            } else if (customKeyFields[index].type === "building") {
              const label = customKeyFields[index].label;
              const element = $(`
                <md-input-container>
                  <div class="row vertically-align-content">
                    <div class="col m4">${label}</div>
                    <div class="col m8">
                      <md-autocomplete md-selected-item="document.building" md-search-text-change="searchTextChange(searchBuildingText)" md-search-text="searchBuildingText" md-selected-item-change="selectedItemChange(item)" md-items="item in querySearchBuildings(searchBuildingText)" md-item-text="item.dashboardName" md-min-length="0">
                        <md-item-template>
                          <span md-highlight-text="searchBuildingText" md-highlight-flags="^i">{{ item.dashboardName }}</span>
                        </md-item-template>
                        <md-not-found>
                          No assets were found.
                        </md-not-found>
                      </md-autocomplete>
                    </div>
                  </div>
                </md-input-container>
              `)[0];

              container.appendChild(element);
            }
          }

          $compile(container)(scope);
        };

        scope.filterForms = function() {
          scope.forms = scope.forms.filter(function(form) {
            return form.isActive;
          });

          scope.documentTypes = _.chain(scope.forms)
            .map(function(form) {
              return form.name;
            })
            .uniq()
            .value();

          if (!_.includes(scope.documentTypes, scope.document.type)) {
            scope.document.type = scope.documentTypes[0];
          }

          scope.hasRetailForms = false;
          scope.hasOfficeForms = false;
          scope.hasIndustrialForms = false;
          scope.dealTypes = _.chain(scope.forms)
            .filter(function(form) {
              return form.name == scope.document.type;
            })
            .map(function(form) {
              if (form.dealType === "retail") {
                scope.hasRetailForms = true;
              } else if (form.dealType === "office") {
                scope.hasOfficeForms = true;
              } else if (form.dealType === "industrial") {
                scope.hasIndustrialForms = true;
              }

              return form.dealType;
            })
            .uniq()
            .value();

          if (scope.dealTypes.length == 1) {
            scope.dealType = scope.dealTypes[0];
          }

          scope.flavors = _.chain(scope.forms)
            .filter(function(form) {
              return (
                form.name == scope.document.type &&
                form.dealType == scope.dealType
              );
            })
            .map(function(form) {
              return form.displayName;
            })
            .uniq()
            .value();

          if (scope.flavors.length == 1) {
            scope.flavor = scope.flavors[0];
            const defaultForm = _.find(scope.forms, function(form) {
              return (
                form.name == scope.document.type &&
                form.dealType == scope.dealType &&
                form.displayName == scope.flavor
              );
            });
            scope.document.form = defaultForm;
            scope.document.type = defaultForm.name;
          } else if (!_.includes(scope.flavors, scope.flavor)) { //
            scope.flavor = null;
          }

          scope.testForm = _.find(scope.forms, function(form) {
            return (
              form.name == scope.document.type &&
              form.dealType == scope.dealType &&
              form.displayName == scope.flavor &&
              form.version === '99999'
            );
          });

          scope.defaultForm = _.find(scope.forms, function(form) {
            return (
              form.name == scope.document.type &&
              form.dealType == scope.dealType &&
              form.displayName == scope.flavor &&
              form.version !== '99999'
            );
          });

          if (scope.document?.form?.customKeyFields) {
            scope.createCustomKeyFields();
          }

          scope.formIncomplete();
        };

        $rootScope.$on('salesforceOpportunitySelected', function(
          event,
          opportunity,
        ) {
          scope.isSalesforceOpportunitySelected = true;
          scope.salesforceOpportunityId = opportunity.id;
          scope.salesforceOpportunityTitle = opportunity.name;
          scope.tenantName = opportunity.accountName;
          // match by Property_Code__c
          let buildingMatch = scope.buildings.filter(building => {
            return building.sfPropertyCode === opportunity.propertyCode_c;
          });
          if (buildingMatch.length === 0) {
            // fallback to match by building name
            buildingMatch = scope.buildings.filter(building => {
              return building.name === opportunity.property_c;
            });
          }
          if (buildingMatch.length > 0) {
            scope.document.building = buildingMatch[0];
          }

          scope.selectFormByOpportunityType(opportunity.opportunityType);
        });

        scope.selectFormByOpportunityType = function(opportunityType) {
          if (opportunityType == 'Lease') {
            scope.flavor = 'retail_lease';
          } else if (opportunityType == 'Specialty Leasing') {
            scope.flavor = 'short_form';
          }
          scope.filterForms();
        };

        $rootScope.$on('vtsDealSelected', function(event, importTask, deal) {
          scope.isVTSDealSelected = true;
          scope.vtsDealID = deal.id;
          scope.importTaskID = importTask.id;
          scope.vtsDealTitle = [deal.propertyName, deal.tenant].join(' - ');
          scope.tenantName = deal.tenant;
          scope.document.type = selectDocumentType(deal.dealType);
          scope.filterForms();

          // this code finds and selects VTS Property in Assets (buildings) dropdown
          var vtsBuildingMatch = scope.buildings.filter(function(building) {
            return (
              building.vtsPropertyId === deal.propertyId ||
              building.name === deal.propertyName
            );
          });
          if (vtsBuildingMatch.length > 0) {
            scope.document.building = vtsBuildingMatch[0];
          }
        });

        const selectDocumentType = (dealType) => {
          // Currently we support VTS import of only two types: Lease and Amendment
          const amendment_deal_types = ['renewal', 'extension', 'expansion', 'relocation', 'termination', 'modification']
          return amendment_deal_types.includes(dealType) ? 'Amendment' : 'Lease'
        }

        scope.showImportVTSDealsDialog = function(event) {
          scope.vtsErrors = [];
          scope.showDarkMask = true;

          $mdDialog
            .show({
              template:
                '<md-dialog><import-vts-deals-modal></import-vts-deals-modal></md-dialog>',
              parent: angular.element(document.body),
              targetEvent: event,
              clickOutsideToClose: false,
              skipHide: true,
              fullscreen: false,
              hasBackdrop: false,
            })
            .finally(function(_data) {
              scope.showDarkMask = false;
            });
        };

        scope.showImportSalesforceOpportunitiesDialog = function(event) {
          scope.showDarkMask = true;

          $mdDialog
            .show({
              template:
                '<md-dialog><import-salesforce-opportunities-modal></import-salesforce-opportunities-modal></md-dialog>',
              parent: angular.element(document.body),
              targetEvent: event,
              clickOutsideToClose: false,
              skipHide: true,
              fullscreen: false,
              hasBackdrop: false,
            })
            .finally(function(_data) {
              scope.showDarkMask = false;
            });
        };

        scope.cancelVTSImport = function() {
          scope.isVTSDealSelected = false;
          scope.vtsDealID = '';
          scope.importTaskID = '';
          scope.vtsDealTitle = '';
          scope.tenantName = '';
          scope.document.building = '';
          scope.vtsErrors = [];
          scope.errors = [];
          scope.creating = false;
        };

        scope.cancelSalesforceImport = function() {
          scope.isSalesforceOpportunitySelected = false;
          scope.salesforceOpportunityId = '';
          scope.salesforceOpportunityTitle = '';
          scope.tenantName = '';
          scope.document.building = '';
          scope.errors = [];
          scope.creating = false;
        };

        function sanitizeParam(params) {
          let cleanParams = JSON.stringify(params);
          
          cleanParams = cleanParams.replace(/{{.*?}}/g, '');

          cleanParams = DOMPurify.sanitize(cleanParams, {
            ALLOWED_TAGS: [],
            ALLOWED_ATTR: [],
          });

          const result = JSON.parse(cleanParams);

          return result;
        }

        scope.createDocument = function() {
          scope.creating = true;
          scope.errors = [];
          var buildingId;
          var document;

          if (scope.document.building) {
            buildingId = scope.document.building.id;
          } else {
            buildingId = null;
          }

          let params = {};

          if (scope.copyDealTerms && window.lease) {
            params = {
              ...window.lease,
            }

            delete params.id;
            delete params.createdAt;
            delete params.updatedAt;
            delete params.modifiedAt;
            delete params.orderedListitem;
            delete params.restyle;
            delete params.layers;
          }

          params.buildingId = buildingId;
          params.formId = scope.document.form.id;
          params.isConformedDeal = attrs.isConformedDeal === 'true';
          params.companyId = scope.companyId;

          if (scope.selectedConformedDeal && scope.canUseConformedDealForBuilding && scope.useConformedDeal) {
            params.conformed_deal = scope.selectedConformedDeal.id;
          } else {
            if (
              scope.isVtsEnabled === true &&
              ['Lease', 'LOI', 'Amendment'].includes(scope.document.type) &&
              scope.isVTSDealSelected === true
            ) {
              params['vtsDealId'] = scope.vtsDealID;
            } else if (
              scope.isSalesforceEnabled === true &&
              ['Lease', 'LOI'].includes(scope.document.type) &&
              scope.isSalesforceOpportunitySelected === true
            ) {
              params['salesforceOpportunityId'] = scope.salesforceOpportunityId;
            }

            if (!scope.document.form.customKeyFields) {
              var tenantNameKey;
              if (scope.dealType === "office") {
                tenantNameKey = "name";
              } else if (scope.dealType === "retail") {
                tenantNameKey = "tradeName";
              } else if (scope.dealType === "industrial") {
                tenantNameKey = "name";
              }
              params.tenantInfo ||= {};
              params.tenantInfo[tenantNameKey] = scope.searchTenantText;
              params.customFields ||= {};
              params.customFields.fileName = params.tenantInfo[tenantNameKey]
            } else {
              const lease = scope.document;

              params = {
                ...params,
                ...scope.document,
              };

              let customKeyFields = scope.document.form.customKeyFields;
              
              if (typeof customKeyFields === "string") {
                customKeyFields = JSON.parse(customKeyFields);
              }

              customKeyFields = customKeyFields.filter(field => field.type !== "building");

              if (!params.customFields) {
                params.customFields = {};
              }
              
              params.customFields.fileName = _.get(lease, customKeyFields[0].ref.replace("lease.", ""));

              for (let index = 1; index < customKeyFields.length; index++) {
                const key = customKeyFields[index].ref.replace("lease.", "");
                params.customFields.fileName += ` - ${_.get(lease, key)}`;
              }
            }
          }

          params = sanitizeParam(params);
          
          document = new DocumentService(
            _.merge(params, { type: scope.document.type }),
          );

          document.create().then(
            function success(doc) {
              let documentType;
              let dealType;
              let flavor;
              let version;
              let building;
              let tenant;

              if (doc) {
                documentType = doc ? doc.form.name.toLowerCase() : '';
                dealType = doc ? doc.form.dealType : '';
                flavor = doc ? doc.form.flavor : '';
                version = doc ? doc.form.version : '';
                building = doc.building ? doc.building.dashboardName : '';

                // TODO: get the name in case this is a custom document
                if (doc.tenantInfo) {
                  tenant = (dealType === 'office')
                    ? doc.tenantInfo.name
                    : doc.tenantInfo.tradeName;
                }
              }

              window.track.event(new CreateANewDocumentEvent({
                documentType,
                dealType,
                flavor,
                version,
                building,
                tenant,
              }));

              scope.redirectToDocument(doc.id, doc.type);
            },
            function error(doc) {
              scope.creating = false;
              scope.errors =
                doc.data && doc.data.errors
                  ? doc.data.errors
                  : ['Unknown error during lease creation'];
            },
          );
        };

        scope.redirectToDocument = function(id, documentType) {
          // A fix for IE 11 (http://tosbourn.com/a-fix-for-window-location-origin-in-internet-explorer/)
          if (!$window.location.origin) {
            $window.location.origin =
              $window.location.protocol +
              '//' +
              $window.location.hostname +
              ($window.location.port ? ':' + $window.location.port : '');
          }
          $window.location.href =
            $window.location.origin +
            '/' +
            _.lowerCase(documentType) +
            's/' +
            id +
            '/edit';
        };

        scope.importDoc = function (file) {
          var formData = new FormData();
          formData.append('lease_file', file);
          $http({
            method: 'POST',
            url: '/api/leases/import_lease_from_json',
            headers: {
              'Content-Type': undefined,
            },
            data: formData,
          }).then(function(response) {
            var document = new DocumentService(response.data);
            document.create().then(function (doc) {
              scope.redirectToDocument(doc.id, doc.type);
            });
          });
        };

        scope.formIncomplete = async function() {
          scope.findConformedDeal();
          await scope.validate();
          
          if (!scope.document.form?.customKeyFields) {
            scope.isFormIncomplete =  (
              !scope.document.building || 
              !scope.searchTenantText || 
              !scope.document.form
            );
          } else {
            scope.isFormIncomplete = false;
          }
        };

        scope.validate = async function() {
          scope.canUseConformedDealForBuilding = false;
          
          if (scope.selectedConformedDeal && scope.document.building) {
            const result = await $http({
              method: "post",
              url: `/api/buildings/exists`,
              headers: {
                "Content-Type": "application/json"
              },
              data: {
                building_id: scope.document.building.id,
                conformed_deal_id: scope.selectedConformedDeal.id,
              }
            })

            if (result.data.exist === false) {
              scope.canUseConformedDealForBuilding = false;
              return;
            } else {
              scope.canUseConformedDealForBuilding = true;
            }
          }

        };

        scope.searchTextChange = function(text) {
          scope.formIncomplete();

          if (window.isDebug) {
            console.info('Text changed to ' + text);
          }
        }
    
        scope.selectedItemChange = function (item) {
          scope.formIncomplete();
          
          if (window.isDebug) {
            console.info('Item changed to ' + JSON.stringify(item));
          }
        }

        function createBuildingFilterFor(query) {
          var lowercaseQuery = query.toLowerCase();
    
          return function filterFn(building) {
            const dashboardName = building.dashboardName.toLowerCase();
            return (dashboardName.indexOf(lowercaseQuery) > -1);
          };
        }

        scope.querySearchBuildings = function (query) {
          var results = query ? scope.buildings.filter(createBuildingFilterFor(query)) : scope.buildings;
          return results;
        }

        function createTenantFilterFor(query) {
          var lowercaseQuery = query.toLowerCase();
    
          return function filterFn(tenant) {
            const tenantName = tenant.name.toLowerCase();
            return (tenantName.indexOf(lowercaseQuery) > -1);
          };
        }

        scope.querySearchTenants = function (query) {
          const tenants = Object.values(scope.tenants).filter(x => Object.keys(x.conformedDeals).length > 0);
          var results = query ? tenants.filter(createTenantFilterFor(query)) : tenants;
          return results;
        }

        scope.init = (function() {
          scope.forms = $rootScope.forms;
          
          if (document.location.search.indexOf('debug') === -1) {
            scope.forms = scope.forms.filter(form => form.flavor !== "rewind");
          }

          scope.buildings = $rootScope.buildings;
          
          scope.filterForms();
        })();
      },
    };
  },
]);
